import React, { useCallback, useEffect, useState } from 'react'
import Link from 'next/link'
import { Formik, Form } from 'formik'

import Button from '@/components/base/Button'
import TextBox from '@/components/base/TextBox'
import RadioCheckbox from '@/components/base/RadioCheckbox'

import styles from '@/styles/Auth.module.css'
import { auth } from '@/validations/login'
import Alert from '@/components/base/Alert'
import { useSession } from '@/contexts/Session'

const Login = ({
    initialValues,
    setEmailAddress,
    setRemember,
    setRedirectToLogin,
    setPassword,
    isNotRequiredOtp,
}) => {
    const [errorMessage, setErrorMessage] = useState('')
    const { setAuthToken } = useSession()
    const {
        authToken: { id },
    } = useSession()
    const onSubmit = useCallback(
        async ({ email, password, remember }, { setSubmitting }) => {
            const signInUrl = `${process.env.NEXT_PUBLIC_API_URL}/auth/login`

            const fetchOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    password,
                    remember,
                    isNotRequiredOtp: isNotRequiredOtp || false,
                    userId: id,
                }),
            }

            const response = await fetch(signInUrl, fetchOptions)

            const responseData = await response.json()

            if (response.ok) {
                setEmailAddress(email)
                setRemember(remember)
                setPassword(password)
                setRedirectToLogin(false)
                if (parseInt(id) !== parseInt(responseData?.userId)) {
                    setAuthToken('isNotRequiredOtp', false, {})
                }
                if (parseInt(id) === parseInt(responseData?.userId)) {
                    setAuthToken(
                        'accessToken',
                        responseData?.data?.accessToken,
                        {
                            path: '/',
                            sameSite: true,
                        }
                    )
                    setAuthToken(
                        'refreshToken',
                        responseData?.data?.refreshToken,
                        {
                            path: '/',
                            sameSite: true,
                        }
                    )
                }
            } else {
                setErrorMessage(responseData?.message)
            }

            setSubmitting(false)
        },
        [
            isNotRequiredOtp,
            setEmailAddress,
            setRemember,
            setPassword,
            setRedirectToLogin,
            id,
            setAuthToken,
        ]
    )
    useEffect(() => {
        if (errorMessage) {
            const timer = setTimeout(() => {
                setErrorMessage(null)
            }, 2000)
            return () => clearTimeout(timer)
        }
    }, [setErrorMessage, errorMessage])

    const options = [{ label: 'Remember me', value: true }]
    return (
        <div>
            <h2 className={styles.heading}>Sign in to your account</h2>
            <h3 className={styles.tagline}>
                Please enter your account credentials to access the system.
            </h3>
            {/* Form  */}
            <div>
                <Formik
                    validationSchema={auth}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validateOnBlur={false}
                    validateOnChange={false}
                >
                    {({ errors, touched, values, isSubmitting }) => {
                        const firstKey = Object.keys(errors)[0]
                        const showError =
                            !!errors[firstKey] && !!touched[firstKey]
                        return (
                            <Form noValidate>
                                <div>
                                    <TextBox
                                        id="email"
                                        name="email"
                                        type="email"
                                        label="Email address"
                                        placeholder="Enter your email address"
                                        isLabel={false}
                                        size="md"
                                    />
                                </div>
                                <div>
                                    <TextBox
                                        id="password"
                                        name="password"
                                        type="password"
                                        label="Password"
                                        placeholder="Enter your password"
                                        isLabel={true}
                                        size="md"
                                    />
                                </div>
                                <div className="flex items-start w-full">
                                    <RadioCheckbox
                                        id="remember"
                                        name="remember"
                                        type="checkbox"
                                        options={options}
                                    />
                                    <div className="ml-auto">
                                        <Link
                                            href="/password/request"
                                            className="font-medium text-primary-500 hover:underline"
                                        >
                                            Forgot Password?
                                            <span className="custom-underline"></span>
                                        </Link>
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        id="submit"
                                        variant="primary"
                                        text="Sign In"
                                        modification="w-full"
                                    />
                                </div>
                                {(errorMessage || showError) && (
                                    <div className="mt-6">
                                        <Alert
                                            variant="error"
                                            message={
                                                errorMessage
                                                    ? errorMessage
                                                    : errors[firstKey]
                                            }
                                            noSpace
                                            icon="info"
                                            iconStyle="text-danger-500 pr-2 text-xs"
                                            isSubmitting={isSubmitting}
                                        />
                                    </div>
                                )}
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default Login
